import React from 'react'
import graphicIcon from '../img/service/1.png'
import devIcon from '../img/service/2.png'
import brandIcon from '../img/service/3.png'

export default function Services() {
  return (
    <>
        <section id="service" className="service parallax-scrl" data-scroll-index="2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="title text-left" data-aos="fade-down">
                            <h3>Our areas of</h3>
                            <h2>Services</h2>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 mt-30">
                        <div className="service-item bg-main"  data-aos="fade-right">
                            <div className="service-icon text-center">
                                <img src={graphicIcon} alt="" />
                            </div>
                            <div className="service-text">
                                <h3>CREATIVE  DESIGN</h3>
                                <p>Put your eyes on your customers while keeping a graphic and aesthetic coherence.</p>
                                <div className="service-line"></div>
                                <ul className="service-list">
                                    <li>Advertising </li>
                                    <li>Graphic Design</li>
                                    <li>Video Editing, VFX and Animation</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 mt-50">
                        <div className="service-item bg-second"  data-aos="fade-up">
                            <div className="service-icon text-center">
                                <img src={devIcon} alt="" />
                            </div>
                            <div className="service-text">
                                <h3>DEVELOPMENT</h3>
                                <p>Top-notch Development that can help you define your brand to increase your online presence.</p>
                                <div className="service-line"></div>
                                <ul className="service-list">
                                    <li>Web  Application</li>
                                    <li>Mobile Application</li>
                                    <li>Desktop Application</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 mt-30">
                        <div className="service-item bg-main"  data-aos="fade-left">
                            <div className="service-icon text-center">
                                <img src={brandIcon } alt="" />
                            </div>
                            <div className="service-text">
                                <h3>BRANDING</h3>
                                <p>We provide end-to-end Innovative strategies and marketing initiatives to all Businesses</p>
                                <div className="service-line"></div>
                                <ul className="service-list">
                                    <li>Digital Marketing</li>
                                    <li>Influencer Marketing</li>
                                    <li>PR works and Branding </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </section>
        <div className="between">
            <div className="line-between"></div>
        </div>
    </>
  )
}
