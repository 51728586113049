import React from 'react'

export default function About() {
  return (
    <>
        <section id="about-us" className="about parallax-scrl">
            <div className="right-bg"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 order-lg-2" >
                        <div className="about-text">
                            <div className="title text-left">
                                <h3 data-aos="fade-left">Who we are  &</h3> 
                            </div>
                            <div className="about-inner"  data-aos="fade-up">
                                <h4>We help brands make smarter decisions</h4>
                                <p className='text-justify'>
                                    As a member of our small and impactful team, each of your contributions makes a visible difference. with talented teammates in an environment that values your life outside of work.
                                </p>
                                <p  className='text-justify'>
                                    Only with voracious curiosity can we build something unprecedented. Our  goals are atypical, so we cannot assume that industry standards or common practices will always be right for us. Our deep commitment to our mission helps us accomplish incredible things. It pushes us beyond our comfort zones, encourages us to learn new skills, and emboldens us to tackle big challenges. We embrace opportunities to grow and respect each other’s perspectives. The magic and success lie in its artistic weirdness, surprising projects, chance inspiration, and novel encounters. 
                                </p>
                            </div>
                            <div className="mt-5">
                                <div className='text-right'>
                                    <emp className='text-white'>
                                        Build a more vibrant and creative world, <br />
                                        Take time and space to enjoy it. 
                                    </emp>
                                </div>
                                <span className='text-white text-right'>
                                    <br />
                                    <div>
                                        <b>Ranjeth</b> <br />
                                        <b className='ej-color'>Founder & CEO</b>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 order-lg-1">
                        <div className="about-img line-img" data-aos="fade-right">
                            <div className="about-overlay"></div>
                            <img className="w-100" src={require('../img/about/aboutus-image.jpeg')} alt="1" />
                        </div>
                    </div>
                </div>
            </div>

        </section>
        <div className="between">
            <div className="line-between"></div>
        </div>
    </>
  )
}
