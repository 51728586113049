import React from 'react'

export default function SocialMedia() {
  return (
    <>
      <li><a href="https://www.instagram.com/ejnarstudios/" target="blank"><i className="fab fa-instagram" aria-hidden="true"></i> </a></li>
      <li><a href="https://wa.me/+917358100831?text=Hello!%20Recently%20visited%20website,%20I%20need%20Digital%20service.%20May%20I,%20know%20the%20details." target="blank"><i className="fab fa-whatsapp" aria-hidden="true"></i></a></li>
      <li><a href="mailto:info@ejnarstudios.com" target="blank"><i className="fa fa-envelope" aria-hidden="true"></i></a></li>
    </>
  )
}
