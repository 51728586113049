import React, { Fragment } from 'react'
import { Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import NavBar from './sections/NavBar';
import AOS from 'aos';
import Contact from './sections/Contact';
import Footer from './sections/Footer';
import Events from './pages/Events';
import ArtBoard from './pages/ArtBoard';
import Academia from './pages/Academia';
import Marketplace from './pages/Marketplace';
import Affiliates from './pages/Affiliates'
import Careers from './pages/Careers'

import 'aos/dist/aos.css';
import './assets/css/plugin.css'
import './assets/css/main.css'
import './assets/css/responsive.css'
import './assets/css/slick.css'



AOS.init({
  duration: 1000
});
 
export default function App() {
  return (
    <Fragment>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/events" element={<Events />} />
        <Route path="/art-board" element={<ArtBoard />} />
        <Route path="/academia" element={<Academia />} />
        <Route path="/market-place" element={<Marketplace />} />
        <Route path="/affiliates" element={<Affiliates />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
      <Footer />
    </Fragment>
  )
}