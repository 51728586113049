import React from 'react'
import logo from './../img/logo.png'
import { Link } from 'react-router-dom'

export default function NavBar() {
  return (
    <header>
      <nav className="navbar bg-dark navbar-expand-sm navbar-dark fixed-top" data-aos="fade-down">
        <div className="container">
          <Link className="navbar-brand" to="/" >
            <img src={logo} alt="Ejnar Studios Logo" width="200px"/>
          </Link>
          <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavId" aria-controls="collapsibleNavId"
            aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="collapsibleNavId">
            <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
              <li className="nav-item"><a className="nav-link" href="#about-us">About</a></li>
              <li className="nav-item">
                <Link className="nav-link" to="/events">Events </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/art-board">Artboard</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/academia">Academia</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/market-place">Marketplace</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/affiliates">Affiliates</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/careers">Careers</Link>
              </li>
              <li className="nav-item"><Link className="nav-link" to="/contact-us">Contact us</Link></li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="line-thumb">
        <div className="line-col first"></div>
        <div className="line-col second"></div>
        <div className="line-col third"></div>
        <div className="line-col fourth"></div>
        <div className="line-col fifth"></div>
      </div>
    </header>
  )
}
