import React from 'react'

export default function Contact() {
    window.scroll(0, 0)
    return (
        <>
            <section className="contact py-5 my-5" id='contact-us'>
                <div className="left-bg" data-aos="fade-right"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="title">
                                <h3 data-aos="fade-right" data-aos-delay="500">Say Hello</h3>
                                <h2 data-aos="fade-right" data-aos-delay="600">Get in touch</h2>
                            </div>
                            <div className="row" data-aos="fade-up" data-aos-delay="700">

                                <div className="col-lg-8  mt-40">
                                    <div className="contact-item text-left" data-aos="fade-left" data-aos-delay="800">
                                        <div>
                                            <div className="mt-10">
                                                <a href="https://www.instagram.com/ejnarstudios/" target="blank"><i className="fab fa-instagram" aria-hidden="true"></i></a>
                                                <a className="mail ml-3" target="blank" href="https://www.instagram.com/ejnarstudios/">@ejnarstudios</a>
                                            </div>

                                            <div className="mt-10">
                                                <a href="https://wa.me/+917358100831?text=Hello!%20Recently%20visited%20website,%20I%20need%20Digital%20service.%20May%20I,%20know%20the%20details." target="blank"><i className="fab fa-whatsapp" aria-hidden="true"></i></a>
                                                <a className="mail ml-3" href="https://wa.me/+917358100831?text=Hello!%20Recently%20visited%20website,%20I%20need%20Digital%20service.%20May%20I,%20know%20the%20details." target="blank">+91-73581 00831 </a>
                                            </div>
                                            <div className="mt-10">
                                                <a href="mailto:info@ejnarstudios.com" target="blank"><i className="fa fa-envelope" aria-hidden="true"></i></a>
                                                <a className="mail ml-3" href="mailto:info@ejnarstudios.com" target="blank">info@ejnarstudios.com</a>
                                            </div>
                                            <div className="mt-10 d-flex">
                                                <a href="https://goo.gl/maps/x5512ecm8spYiDX58" target="blank"><i className="fa fa-map" aria-hidden="true"></i></a>
                                                <a className="mail ml-3" href="https://goo.gl/maps/x5512ecm8spYiDX58" target="blank">
                                                    GREETA TOWERS, Industrial Estate, Perungudi, Chennai-96
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 mt-30">
                            <div className="about-img line-img" style={{ 'position': 'relative' }} data-aos="fade-up" data-aos-delay="800">
                                <div className="about-first" style={{ "width": "0px" }}></div>
                                <div className="about-overlay"></div>
                                <img className="img-fluid" src={require('./../img/contact.jpg')} alt="contact us" />
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}
