import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import emailjs from '@emailjs/browser';

export default function Footer() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
 
    emailjs.sendForm('service_w5ui9al', 'template_c45aef5', form.current, 'WiKMtxce6xy3UacLE')
      .then((result) => {
        console.log(result.text);
        window.location.reload()
      }, (error) => {
        console.log(error.text);
      });
  };
  return (
    <>
      <section style={{ background: "#222222" }}>
        <div className="container p-0 py-5">
          <div className="d-flex align-items-center mb-3">
            <img src={require('../img/ejnarstudios[L01].png')} alt="EjnarStudios" width="25px" />
            <h3 className='text-white mx-2 my-0'>| Ejnarstudios</h3>
          </div>
          <div className="row m-0 ">
            <div className="col-lg-8 footer-links-group ">
              <div class="footer-links">
                <Link to='/' className='active'> Home </Link>
                <Link to='/'> About </Link>
                <Link to='/'> Services </Link>
                <Link to='/'> Founder </Link>
                <Link to='/'> Contact us </Link>
              </div>
              <div class="footer-links">
                <Link to='/' className='active'> Services </Link>
                <Link to='/'> Website </Link>
                <Link to='/'> Advertising </Link>
                <Link to='/'> Mobile Apps </Link>
                <Link to='/'> Video editing</Link>
              </div>
              <div class="footer-links">
                <Link to='/' className='active'> Affiliates </Link>
                <Link to='/'> Reference </Link>
                <Link to='/'> Consultation </Link>
                <Link to='/'> Percentage norms </Link>
              </div>
              <div class="footer-links">
                <Link to='/' className='active'> Events </Link>
                <Link to='/'> Workshop </Link>
                <Link to='/'> Conference </Link>
                <Link to='/'> Symposium </Link>
                <Link to='/'> Corporate events </Link>
              </div>
              <div class="footer-links">
                <Link to='/' className='active'> Academia </Link>
                <Link to='/'> News </Link>
                <Link to='/'> Article </Link>
                <Link to='/'> Public sharing </Link>
                <Link to='/'> Knowledge base </Link>
              </div>
              <div class="footer-links">
                <Link to='/' className='active'> Support </Link>
                <Link to='/'> Products </Link>
                <Link to='/'> Help centre </Link>
                <Link to='/'> Special offers </Link>
              </div>
              <div class="footer-links">
                <Link to='/' > Graphic design </Link>
                <Link to='/'> Digital Marketing </Link>
                <Link to='/'> Software solutions </Link>
                <Link to='/'> Influencer Marketing </Link>
              </div>
              <div class="footer-links">
                <Link to='/' className='active'> Art board </Link>
                <Link to='/'> Design </Link>
                <Link to='/'> Posters </Link>
                <Link to='/'> Templates </Link>
                <Link to='/'> Abstract Layout </Link>
              </div>
              <div class="footer-links">
                <Link to='/' className='active'> Marketplace </Link>
                <Link to='/'> Products </Link>
                <Link to='/'> Downloads </Link>
                <Link to='/'> Applications </Link>
              </div>
              <div class="footer-links">
                <Link to='/' className='active'> Careers </Link>
                <Link to='/'> Internship </Link>
                <Link to='/'> Job Vacancy </Link>
                <Link to='/'> Join our team </Link>
              </div>
            </div>
            <div className="col-md-6 mx-auto col-lg-4 py-4">
              <div className="title text-center m-0 p-3">
                <h3 className='m-0'>Get in touch</h3>
              </div>
              <form ref={form} onSubmit={sendEmail} >
                <input required placeholder='Enter your name' type="text" name="name"  className='ej-control text-white bg-dark '/>
                <input required placeholder='Enter your number' type="number" name="mobile"  className='ej-control text-white bg-dark '/>
                <input required placeholder='Enter your email' type="email" name="email"  className='ej-control text-white bg-dark '/>
                <textarea placeholder='Message' name="message" className='ej-control text-white bg-dark ' required/>
                <div className="mt-40 title-anim text-center">
                  <button className="btn-main text-white bg-dark" type="submit">Connect now</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>  
      <section className="copyrights" >
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center py-3">
                    <div className="copyright">
                      <span className='text-secondary'>
                        Copyright ©{(new Date().getFullYear())} <span style={{ color:"#D7AF7B" }}>ejnarstudios</span> . All rights reserved | Privacy Policy | Terms of Use | Do not sell my personal information
                      </span>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}
