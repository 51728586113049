import React from 'react'

export default function Events() {
  window.scroll(0,0)

  return (
    <>
      <div className="py-5 jumbotron-fluid d-flex align-items-center justify-content-center" style={{ minHeight:"80vh" }}>
        <div className="container text-center h-100">
            <h1 className="display-4 ej-text">Events</h1>
            <p className="ej-text-white">Community for Connection, Creativity and Collaboration</p>
            <p>
                Made by and for creatives who believe in the power of ideas and the joy of making them happen
            </p>
            <div className="mt-40 title-anim" data-aos="fade-left">
                <a className="btn-main" href="#about-us" >Join our community</a>
            </div> 
        </div> 
      </div>
      <div className='text-white text-center py-5'>
        Something awesome is coming, it's going to be amazing! Sign up to find out when it's ready.
      </div>
    </>
  )
}
