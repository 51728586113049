import React from 'react'

export default function ArtBoard() {
  return (
    <>
        <div className="py-5 jumbotron-fluid d-flex align-items-center justify-content-center" style={{ minHeight:"80vh" }}>
        <div className="container text-center h-100">
            <h1 className="display-4 ej-text">Artboard </h1>
            <p className="ej-text-white">You Dream, We Design   </p>
            <p>
            We are creative Peoples who makes awesome design for thousands of peoples
            </p>
            <div className="mt-40 title-anim" data-aos="fade-left">
                <a className="btn-main" href="#about-us" >Join our community</a>
            </div> 
        </div> 
        </div>
        <div className='text-white text-center py-5'>
        Something awesome is coming, it's going to be amazing! Sign up to find out when it's ready.
        </div>
    </>
  )
}
