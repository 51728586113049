import React from 'react'
import Slider from "react-slick";
import _ from "lodash";

export default function Testimonial() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false
    };
    var clientSettings = {
        dots: false,
        arrows:false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 4000,
        cssEase: "linear",
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };
    return (
        <> 
            <section id="client" className="testimonial fades" data-scroll-index="6">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mid-title">
                            <div className="title text-center" >
                                <h3 data-aos="fade-down">Testimonial &</h3>
                                <h2 data-aos="fade-up">Quotes</h2>
                            </div>
                        </div>
                        <div className="col-lg-10 offset-lg-1 mt-20 fades">
                            <div className="quot-left">
                                <i className="fa fa-quote-left" aria-hidden="true"></i>
                            </div>
                            <div className="quot-right">
                                <i className="fa fa-quote-right" aria-hidden="true"></i>
                            </div>
                            <div className="testimonial" data-aos="fade-up" data-aos-delay="500">
                                
                                <Slider  {...settings} className="owl-carousel owl-theme">

                                    <div className="item-testi-slide">

                                        <div className="testimonial-item text-center">
                                            <p>"We have given our company website creation to EjnarStudios. They completed their work with in specified time period. Website was more Professional and are flexible to make changes/editing in website even after job was completed. I'm  happy with this company."</p>
                                            <div className="author">
                                                <p className="author-text">Mr.Vetri Selvam, Software Agency</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="item-testi-slide">

                                        <div className="testimonial-item text-center">
                                            <p>" Awesome Ecommerce Website Company I strongly recommend for other services to my friends and colleagues. Quality Work and Talented Team "</p>
                                            <div className="author">
                                                <p className="author-text">Mr.Logesh, Digital Influencer</p>
                                            </div>
                                        </div>

                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div className="line-testi"></div>
                </div>
            </section>

            <div className="client mid-title fades ">
                <br />
                <div className="container">
            
                    <div className="partner">
                        <Slider  {...clientSettings} className="owl-carousel owl-theme">
                            {
                                _.times(27, (i) => (
                                    <div className="item_partner" key={i}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <img src={require(`../img/partner/client (${i +1}).png`)} width="200px" alt="1" />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            } 
                        </Slider>
                    </div>
                </div>
            </div>

            <div className="between">
                <div className="line-between"></div>
            </div>
        </>
    )
}
