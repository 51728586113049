import React from 'react'
import SocialMedia from './SocialMedia'

export default function HeroBanner() {
  return (
    <section className="hero centered">
        <div className="hero-bg" >
            <div className="square" data-aos="fade-up-left"></div>
            <div className="square-overlay"></div>
        </div>
        <div className="email-link title-anim" >
            <a href="#" data-aos="fade-right" data-aos-delay="500">info@ejnarstudios.com</a>
        </div>
        <div className="social title-anim">
            <ul data-aos="fade-left" data-aos-delay="500">
                <SocialMedia />
            </ul>
        </div>
        <div className="container between">
            <div className="row">
                <div className="col-lg-6">
                    <div>
                        <div className="hero-title text-left">
                            <h3 className="title-anim" data-aos="fade-right">We are</h3>
                            <h1 className="title-anim" data-aos="fade-right">Creative Agency</h1>
                            <div className="mt-20"></div>
                            <p className="title-anim" data-aos="fade-up">Digital agency based in Chennai, India that loves to craft
                                attractive experiences for the web.</p>
                            <div className="mt-40 title-anim" data-aos="fade-left">
                                <a className="btn-main" href="#about-us" >About us</a>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div>
                        <img src={ require('../img/awards/award (1).png') } width="100px" className='my-3 mr-3' alt="awards" />
                        <img src={ require('../img/awards/award (2).png') } width="100px" className='my-3 mr-3' alt="awards" />
                        <img src={ require('../img/awards/award (3).png') } width="100px" className='my-3 mr-3' alt="awards" />
                        <img src={ require('../img/awards/award (4).png') } width="100px" className='my-3 mr-3' alt="awards" />
                    </div>
                </div>
            </div>
        </div> 
    </section>
  )
}
