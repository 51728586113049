import React from 'react'

export default function Careers() {
  return (
    <>
      <div className="py-5 jumbotron-fluid d-flex align-items-center justify-content-center" style={{ minHeight:"80vh" }}>
        <div className="container text-center h-100">
            <h1 className="display-4 ej-text">Careers   </h1>
            <p className="ej-text-white">
              Where do you want to go 
            </p>
            <p>
              We believe that art and creative expression are essential to a healthy and vibrant society. If you do too, we can't wait to hear from you.
            </p>
            <div className="mt-40 title-anim" data-aos="fade-left">
                <a className="btn-main" href="#about-us" >Join our community</a>
            </div> 
        </div> 
      </div>
      <div className='text-white text-center py-5'>
        Something awesome is coming, it's going to be amazing! Sign up to find out when it's ready.
      </div>
    </>
  )
}
